import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PageHeader = ({ heading, description, image }) => (
  <Fragment>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, .35) 100%), url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2 content">
            <div>
              <h3 className="heading section-title is-size-2 banner-titles">{heading}</h3>
            </div>
            <div>
              <p className="description theme-p banner-titles">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="banner-frame"></div>
  </Fragment>
);

PageHeader.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default PageHeader
