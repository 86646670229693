import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PageFooter = ({ heading, description, image }) => (
  <Fragment>
    <div className='footer'>
      <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-6">
                <div className="tile">
                  <div className="hubs-logo"></div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column footer-content">
                <p><mark>Hubsharp Solutions, LLC</mark></p>
                <p>7017 N 10th St Suite N2 #320</p>
                <p>McAllen, TX 78504</p>
                <p><a href="mailto:info@hubsharp.com">info@hubsharp.com</a></p>
                <p>512.200.3524</p>
              </div>
            </div>
          </div>
        </section>
    </div>
  </Fragment>
);

PageFooter.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default PageFooter
