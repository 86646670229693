import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-white.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-white"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-logo" title="HubSharp Solutions, LLC">
              <img src={logo} alt="HubSharp Solutions, LLC"  />
            </Link>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/services">
                Services
              </Link>
              <Link className="navbar-item" to="#Customer-Successes">
                Customers
              </Link>
              <Link className="navbar-item" to="/team">
                Team
              </Link>
              {/* <a href="https://hubsharpsolutions.applytojob.com/apply" class="navbar-item">Careers</a> */}
              <div class="navbar-item">
                <div class="buttons">
                  <Link className="button is-primary" to="/contact">
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
